.info-pair__heading {
    color: $mutedText;
    margin-bottom: 5px;
}

.info-pair__content {
    font-weight: bold;
    margin-bottom: 10px;
}

.info-pair__content--lighter {
    font-weight: normal;
}
