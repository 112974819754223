.v2__accordion-tab {
    flex-direction: column;
    display: flex;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
    &--unassigned-visits {
        margin-left: -10px;
        width: 250px;
        .v2__accordion-summary-edit{
            display: none;
        }
        .v2__accordion-content {
            border: none;
        }
        .v2__accordion-summary{
            border-radius: 4px;
            height: 35px;
            margin: 5px;
            &:hover {
                background-color: $color-blue;
            }
            &:first-child{
                &--open {
                    background-color: $color-blue;
                    margin-top: 0px;
                }
            }
            &--open {
                background-color: $color-blue;
                margin-top: 20px;
                &:hover {
                    cursor: pointer;
                    background-color: $color-blue-dark;
                }
            }
        }
        .v2__accordion-summary-name {
            font-size: $font-size-p;
        }
    }
}

.v2__accordion-summary {
    transition: background-color $duration-mouse-over $function-mouse-over;
    align-items: center;
    justify-content: space-between;
    display: flex;

    &--blue {
        color: $color-white;
        fill: $color-white;
        background-color: $color-blue-light;
    }
    &--blue.v2__accordion-summary--clickable:hover {
        background-color: mix($color-white, $color-blue-light, 10%);
    }

    &--dark-blue {
        background-color: $color-blue-dark;
        color: $color-white;
        fill: $color-white;
    }

    &--pink {
        color: $color-white;
        fill: $color-white;
        background-color: $color-pink;
    }
    &--pink.v2__accordion-summary--clickable:hover {
        background-color: mix($color-white, $color-pink, 10%);
    }

    &--grey {
        color: $color-text-main;
        fill: $color-text-main;
        background-color: $color-border-main;
    }
    &--grey.v2__accordion-summary--clickable:hover {
        background-color: mix($color-white, $color-border-main, 10%);
    }

    &--open {
        border-radius: $border-radius-sm $border-radius-sm 0 0;
    }
    &--closed {
        border-radius: $border-radius-sm;
    }

    &--clickable {
        cursor: pointer;
    }

    &--disabled {
        cursor: default;
    }
}

.v2__accordion-summary-left,
.v2__accordion-summary-right {
    align-items: center;
    display: flex;
}

.v2__accordion-summary-name {
    @include font-h3;
    margin-left: 20px;

    &--relative {
        position: relative;
    }
}
.v2__accordion-summary-edit {
    padding: 13px;
    display: flex;
}
.v2__accordion-summary-icon {
    padding: 13px 20px;
    display: flex;
}
.v2__accordion-summary-edit > svg,
.v2__accordion-summary-icon > svg {
    width: 24px;
    height: 24px;
}

.v2__accordion-wrapper {
    min-height: 0;
    overflow: hidden;

    &--open {
        pointer-events: all;
        visibility: visible;
    }
    &--closed {
        height: 0;
        pointer-events: none;
        visibility: hidden;
    }
}

.v2__accordion-content {
    border: 1px solid $color-border-main;
    border-top: none;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
}

.v2__accordion-icon-wrap {
    position: absolute;
    width: 30px;
    height: 25px;
    right: -39px;
    top: 0px;
    cursor: pointer;
}

.v2__accordion-editIcon {
    width: 19;
    height: 20px;
}
.unassigned-visits-wrapper {
    &:first-child {
        .v2__accordion-summary--open{
            margin-top: 5px;
        }
    }

}
