.v2__dialog {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;

    overflow: hidden auto;
    position: absolute;
    z-index: 51;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &--show {
        transition: transform $duration-appearance step-start,
            visibility $duration-appearance step-start;
        transform: translateY(0);
        visibility: visible;
        pointer-events: auto;
    }

    &--hide {
        transition: transform $duration-appearance step-end,
            visibility $duration-appearance step-end;
        transform: translateY(-100vw);
        visibility: hidden;
        pointer-events: none;
    }
}

.v2__dialog-wrapper {
    padding: 50px 0;
    width: 100%;

    transition: transform $duration-appearance $function-appearance,
        opacity $duration-appearance $function-appearance;

    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;

    &--show {
        transform: translateY(0px);
        opacity: 1;
    }

    &--hide {
        transform: translateY(50px);
        opacity: 0;
    }
}

.v2__dialog-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
}

.v2__dialog-content {
    width: calc(100vw - 32px);
    background-color: $color-white;
    border-radius: $border-radius-md;
    position: relative;
    z-index: 60;

    &--small {
        max-width: 500px;
    }
    &--large {
        max-width: 1100px;
    }

    .bp5-dialog-header {
        box-shadow: none;
        padding-right: 0;
    }

    .bp5-dialog-close-button {
        padding: 24px;
        width: 68px;
        height: 68px;
        transition: fill $duration-mouse-over $function-mouse-over;
        cursor: pointer;

        &:hover {
            fill: $color-text-main;
        }

        svg {
            fill: $color-grey;
        }
    }
}

.v2__dialog-header {
    padding-left: 24px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.v2__dialog-title {
    @include font-h3;
    color: $color-text-main;
    &--blue {
        color: $color-blue;
    }
}
.v2__dialog-header-right-block {
    display: flex;
    align-items: center;
    justify-content: center;
}
.v2__dialog-header-update-by {
    margin-right: 10px;
    font-size: 0.85em;
    margin-top: 5px;
    align-self: flex-end;
}
.v2__dialog-close {
    padding: 24px;
    width: 68px;
    height: 68px;
    fill: $color-grey;
    transition: fill $duration-mouse-over $function-mouse-over;
    cursor: pointer;

    &:hover {
        fill: $color-text-main;
    }
}

.v2__dialog-main {
    padding: 0 24px 24px;
    flex-direction: column;
    display: flex;
}

// CONTENT

.v2__dialog-block {
    margin-bottom: 20px;
    &--no-margin {
        margin: 0px;
    }
}

.v2__dialog-description {
    @include font-h4;
    font-weight: normal;
    color: $color-text-main;
    padding: 0px 20px;
    margin-bottom: 10px;
}

.v2__dialog-block-list {
    padding-inline-start: 20px;
    list-style-type: none;
}
.v2__dialog-block-list-item > span {
    padding: 4px 0;
    min-width: 100px;
    display: inline-block;
}

.v2__dialog-group {
    margin-bottom: 20px;
    align-items: center;
    display: flex;

    &--between {
        justify-content: space-between;
    }
}
.v2__dialog-between > div {
    width: 100%;
}

.v2__dialog-buttons {
    align-items: center;
    display: flex;

    &--left {
        justify-content: flex-start;
    }
    &--right {
        justify-content: flex-end;
    }
}

.v2__dialog-between > div:not(:last-child),
.v2__dialog-buttons > div:not(:last-child),
.v2__dialog-buttons > button:not(:last-child) {
    margin-right: 15px;
}
.v2__dialog-radiobuttons-group-container {
    display: flex;
    flex-direction: column;
}
.v2__dialog-radiobuttons-group {
    margin: 0 10px;
    padding: 4px 8px;
    font-size: 0.9em;
    background-color: rgba($color-white, 0.25);
    border-radius: 4px;
    color: $color-black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.v2__dialog-setTime-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    .v2__dialog-setTime-text {
        width: 180px;
    }
    .v2__form-date-input{
        width: 180px;
    }
}
.v2__dialog-setTime-text {
    align-content: flex-start;
}
.v2__dialog-time-input-block {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 10px;
}
.v2__dialog-setTime-text {
    margin-right: 10px;
}
.v2__dialog-change-visit-status-buttons-container {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}
.v2__dialog-change-visit-status-button {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--big {
        width: 180px;

        justify-content: center;
    }
}
.visit-details__header-actions-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    background-color: $color-white;
    color: $color-blue-dark;
    border: 1px solid $color-blue-dark;
    font-size: $font-size-h4;
    margin-right: 10px;
}
.visit-details__header-actions-list-row {
    cursor: pointer;
    height: 30px;
    width: 100%;
    outline: none;
    user-select: none;
    text-decoration: none;
    border: none;
    color: $color-blue-dark;
    background-color: $color-light-50;
    border-bottom: 0.5px solid lightgray;
    text-align: start;
    padding: 7px;

    &:hover {
        background-color: $color-blue-bg;
        box-shadow: 0 0 0 1px $color-border-main;
    }
}
