// COLOURS:
$color-white: #ffffff;
$color-grey: #7f939c;
$warning: #d9822b;
$warningBackground: #f1e8de;
$warning-darker: #bf7326;
$success: #29a634;
$success-darker: #0d8050;
$successBackground: #d8ebe5;
$information: #1c7cd5;
$informationBackground: #d8e8f0;
$information-darker: #106ba3;
$danger: #d9534f;
$danger-darker: #c23030;
$dangerBackground: #f2e0df;
$text: #232323;
$primary: $information;
$primaryBackground: $informationBackground;
$primary-darker: $information-darker;
$secondary: #dbdbdb;
$available: $warning;
$accepted: $success;
$arrived: $information;
$current: #800080;
$requireAttention: $danger;
$historic: #8c8c8b;
$mutedText: #5c7080;
$divider: #ccc;
$mutedBackground: #eee;
$fadedBackground: #f6f9f9;
$alt: #cb307e;
$tertiary: #cb307e;
$personCategoryTitleColor: #275592;
$personTitleColor: #546e7a;
$personTextColor: #1c3563;
$personInputBorderColor: #acb9bf;
$personBorderColor: #e2e6e8;
$lightGreyBackground: #f6f7f7;
$noIntentButtonColor: #546e7a;
$noIntentHoverButtonColor: #465962;

.available-bg {
    background-color: $available;
    color: white;
}

.accepted-bg,
.completed-bg {
    background-color: $accepted;
    color: white;
}

.current-bg {
    background-color: $current;
    color: white;
}

.arrived-bg {
    background-color: $arrived;
    color: white;
}

.withdrawn-bg,
.hcp_aborted-bg {
    background-color: $requireAttention;
    color: white;
}

.controller_aborted-bg {
    background-color: $mutedBackground !important;
    color: $mutedText;
}

.underlined--secondary {
    border-bottom: 1px solid rgba($tertiary, 0.25);
}
