.pickup-details-dialog__route-table-header-section  {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.pickup-details-dialog__route-table-header-section .left {
    visibility: hidden;
}

.pickup-details-dialog__route-table-header-section .right {
    margin-right: 20px;
}

.pickup-details-dialog__checkbox-mass-select-link-like {
  align-items: normal;
  background-color: rgba(0,0,0,0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: rgb(0, 0, 238);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}
.pickup-details-dialog__checkbox-mass-select {
  display:flex
}
.pickup-details-dialog__select-all-container {
  margin-bottom: 10px;
}

