.dialog--wide {
    width: 80%;
}

.dialog--wide {
    max-width: 800px;
}

// Adjust react-select components to be same height as blueprint form fields
.react-select > div {
    min-height: 40px;
}

.bp5-html-table {
    width: 100%;
}

// Maybe in the future if we will need Popover2 with arrow add additional wrapper
.bp5-popover2-arrow {
    display: none;
}

.bp5-button.bp5-outlined {
    background-color: rgba(white, 0.75);
}

.bp5-html-select.bp5-minimal select,
.bp5-select.bp5-minimal select {
    border: 1px solid $divider;
    background-color: white;
}

// Min height for the empty select field
.common-select__has-empty div[class$='option'] {
    min-height: 34px;
}

.bp5-alert-contents {
    flex-grow: 1;
}

.bp5-alert .bp5-html-select .bp5-icon {
    margin-right: 0;
}

// Make alert overlay the highest z-index
.bp5-overlay.bp5-overlay-open.bp5-overlay-scroll-container {
    z-index: 70;
}

.bp5-transition-container {
    &.bp5-popover-appear-done, &.bp5-popover-enter-done {
        z-index: 65;
    }
}

.bp5-datepicker .DayPicker-Month {
    min-width: 220px;
}
// I'm not 100% sure that the blueprint5 changes should go here, it was a guess as this
// File is called overrides
// it may be better to make the changes in more specific scss files eg the bp5-alert changes could go in
// src/assets/alert.scss

// VSU-2303 fix to make the calender popup show above the modal.
.bp5-portal {
    z-index: 9999;
}

// VSU-2303 this color correction puts us back to the color under blueprintjs3
// the current background for blueprintjs 5 is #F6F7F9
// which is close to our --bg-c-secondary: #F6F7F7
div.bp5-dialog.bp5-alert {
    background:#ebf1f5;
}
// VSU-2303 fix for day numbers in the date picker being broken up
// was inheriting from .bp5-alert-contents  -> word-break: break-word
.bp5-datepicker {
    word-break: normal;
}


/* VSU-2303 height fix, also see src/assets/alert.scss  .bp5-input is also modified there, it maybe that we do a global solution for .bp5-input */
.bp5-input-group.bp5-date-input.bp5-popover-target {
    .bp5-input {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }
}