$timeline-height: 40px;

.ucr__calendar {
    padding: 20px;
    overflow: hidden;
}

.ucr__calendar-wrapper {
    height: 100%;
    border: 1px solid $color-border-grid;
    box-sizing: border-box;
    overflow: auto;
    background-color: rgba(138, 155, 168, 0.15);
}

.ucr__calendar-content {
    width: fit-content;
    height: fit-content;
    display: flex;
}

.ucr__calendar-side {
    flex: 0 0 auto;
    width: 460px;
    left: 0;
    position: sticky;
    z-index: 5;
}

.ucr__calendar-main {
    flex: 1 1 auto;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    display: flex;
}

.ucr__calendar-buttons {
    height: $timeline-height;
    background-color: $color-white;
    border-bottom: 1px solid $color-border-grid;
    border-right: 1px solid $color-border-grid;

    display: flex;
    align-items: center;

    top: 0;
    left: 0;
    position: sticky;
    z-index: 5;
}

.ucr__calendar-buttons-button-wrapper {
    display: flex;
    margin-left: 15px;
}

.ucr__calendar-hcps {
    border-right: 1px solid $color-border-grid;
}

.ucr__calendar-hcp-top {
    padding: 20px 10px 10px 15px;
    width: 100%;
    background-color: $color-light;
    align-items: flex-start;
    box-sizing: border-box;
    position: relative;
    display: flex;

    &--warning {
        background-color: #fff;
        background-color: $color-hcp-bg-warning;
    }
}

.ucr__calendar-hcp-bottom {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
    background-color: $color-light;
    align-items: flex-start;
    box-sizing: border-box;
    position: relative;
    display: flex;
}

.ucr__calendar-hcp--staff-alert > .ucr__calendar-hcp-top {
    background-color: mix($color-white, $color-red, 10%);
}

.ucr__calendar-hcp {
    width: 100%;
    flex-wrap: wrap;
    background-color: $color-light;
    align-items: flex-start;
    box-sizing: border-box;
    position: relative;
    display: flex;
    &:not(:last-child) {
        border-bottom: 1px solid $color-border-grid;
    }

    &--staff-alert {
        background-color: mix($color-white, $color-red, 10%);
        color: $color-light;
    }
}

.ucr__calendar-hcp-toggle {
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;

    fill: $color-white;
    background-color: $color-grey;
    border-radius: $border-radius-sm;
    transition: background-color $transition-mouse-over;

    align-items: center;
    justify-content: center;
    display: flex;

    position: absolute;
    cursor: pointer;

    border: 0;
    padding: 0;

    &:hover {
        background-color: mix($color-white, $color-grey, 10%);
    }

    &--map {
        right: 40px;
    }
}
.ucr__calendar-hcp-toggle > svg {
    width: 16px;
    height: 16px;
}

.ucr__calendar-hcp-image {
    margin-right: 10px;
    position: relative;
}

.ucr__calendar-hcp-avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: $color-grey;
    background-color: $color-light;

    &--available {
        border: 1px solid $color-green;
    }

    &--unavailable {
        border: 1px solid $color-grey;
    }
}

.ucr__calendar-hcp-status {
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    position: absolute;
    display: block;

    &--available {
        background-color: $color-green;
        border: 1px solid $color-light;
    }

    &--unavailable {
        background-color: $color-grey;
        border: 1px solid $color-light;
    }
}

.ucr__calendar-scheduling-conflict {
    padding: 4px;
    padding-bottom: 5px;
    position: relative;
    top: 2px;
}

.ucr__calendar-scheduling-conflict-icon {
    fill: $color-white;
}

.ucr__calendar-hcp-push-pin {
    transform: rotate(45deg) scale(0.4);
    background-color: transparent;
    border: none;
    font-size: 12px;
    fill: #546e7a;
    position: absolute;
    display: inline;
    margin: 0;
    padding: 0;
    left: 50px;
    top: 5px;
    cursor: pointer;
    &--pinned {
        fill: #3281ac;
    }
    &--tooltip {
        color: #546e7a;
    }
}

.ucr__calendar-hcp-push-pin:focus {
    outline: 0;
}

.ucr__calendar-hcp-name {
    @include font-h4;
    margin-left: 20px;

    &--warning {
        color: $color-hcp-text-warning;
    }
}

.ucr__calendar-hcp-about {
    &--pl {
        padding-left: 20px;
    }
}

.ucr__calendar-hcp-userId {
    @include font-p;
    margin-left: 20px;
    display: block;
}

.ucr__calendar-hcp-phone {
    margin-top: 5px;
    @include font-p;
    font-weight: 300;
    display: block;
    margin-left: 20px;
}

.ucr__calendar-hcp-badges {
    margin-left: 15px;
}

.ucr__calendar-hcp-badge {
    margin: 5px 0 0 5px;
    padding: 4px 8px;
    @include font-p;
    color: $color-text-main;
    background-color: $color-border-main;
    border-radius: $border-radius-sm;
    display: inline-block;
    cursor: default;
}

.ucr__calendar-timeline {
    height: $timeline-height;
    background-color: $color-white;
    border-bottom: 1px solid $color-border-grid;
    grid-template-columns: repeat(24, 1fr);
    display: grid;

    top: 0;
    position: sticky;
    z-index: 4;
}

.ucr__calendar-hour-item {
    padding: 4px;
    height: $timeline-height;
    align-items: flex-end;
    display: flex;

    &:not(:last-child) {
        border-right: 1px solid $color-border-grid;
    }
}

.ucr__calendar-hour-text {
    @include font-p;
    color: $color-text-main;
}

.ucr__calendar-time-grid,
.ucr__calendar-container {
    top: $timeline-height;
    left: 0;
    width: 100%;
    height: calc(100% - #{$timeline-height});
    position: absolute;
}

.ucr__calendar-container-placeholder {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed $color-grey;
    transition: opacity $transition-mouse-over;
    position: absolute;
    display: block;
    pointer-events: none;
    opacity: 0;

    &--hover {
        opacity: 1;
    }
}

.ucr__calendar-current-time {
    height: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 4;
}

.ucr__calendar-current-time-dot {
    position: sticky;
    top: 0;
    left: 0;
    margin-left: -4px;
    background-color: $color-white;
    display: inline-block;
    z-index: 3;

    &::after {
        margin-top: 10px;
        width: 8px;
        height: 8px;
        content: '';
        background-color: $color-pink;
        border-radius: 50%;
        display: block;
    }
}

.ucr__calendar-current-time-text {
    @include font-p;
    white-space: nowrap;
    color: $color-pink;
    position: sticky;
    top: 6px;
    margin-left: 6px;
}

.ucr__calendar-current-time-line {
    height: calc(100% - 20px);
    border-left: 2px dashed $color-pink;
    pointer-events: none;
    display: block;
    position: absolute;
    top: 20px;
    left: -1px;
}

.ucr__calendar-draglayer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: fixed;
    z-index: 10;
}

.ucr__calendar-job-draggable {
    display: inline-block;

    &--calendar {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 3;
    }
    &--visits {
        margin: 0;
        width: 250px;
        position: relative;
        z-index: 2;
        padding: 5px;
        box-sizing: border-box;
    }
}

.ucr__calendar-job-preview {
    display: inline-block;

    &--visits {
        width: 250px;
    }
}

.ucr__calendar-job {
    display: flex;

    &--visits {
        height: auto !important;
        width: auto !important;
    }
    &--calendar {
        padding: 4px 1px;
    }
}

.ucr__calendar-job-offset {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    right: 0;
    top: 4px;
    height: calc(100% - 8px);
    opacity: 0.88;
    background-color: $color-orange;
    border-top-right-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
}

.ucr__calendar-job-inner {
    cursor: pointer;
    border: none;
    padding: 0;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: $border-radius-sm;

    &--active-focused {
        box-shadow: 0 0 0 4px rgba($color-pink, 0.5);
        outline: none;
        cursor: default;
    }
    &--active {
        box-shadow: 0 0 0 4px rgba($color-grey, 0.5);
        outline: none;
    }

    &--draggable {
        cursor: grab;
    }

    &--has-been-dragged {
        cursor: wait;
    }

    &--preview {
        cursor: grabbing;
    }
}

.ucr__calendar-job-main {
    flex: 1 1 auto;
    border: 1px solid transparent;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    position: relative;
    color: $color-text-dark;
    background-color: $color-task-bg-urgent;

    &--unassigned {
        border-radius: $border-radius-sm;
    }

    // background-color colors (follow-up | urgent | administrative)
    &--follow-up {
        background-color: $color-task-bg-follow-up;
    }

    &--urgent {
        background-color: $color-task-bg-urgent;
    }

    &--alert {
        &-unresolved {
            color: $color-task-text-alert;
            background-color: $color-task-bg-alert;
        }
        &-resolved {
            background-color: $color-task-bg-urgent;
        }
    }

    &--administrative-time {
        background-color: $color-task-bg-administrative;
    }
    &--all-admin-times-without-administarive {
        background-color: $color-blue-bg;
    }
    &--urgent-responder {
        background-color: $color-task-bg-coordinator;
    }
    &--coordinator {
        background-color: $color-task-bg-coordinator;
    }
    &--light-blue {
        background-color: $color-legend-and-box-light-blue;
    }
    &--light-grey {
        background-color: $color-legend-and-box-light-grey;
    }
    &--light-green {
        background-color: $color-legend-and-box-light-green;
    }

    &--partially-assigned::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0) 10px,
            rgba(255, 255, 255, 0.5) 10px,
            rgba(255, 255, 255, 0.5) 20px
        );
    }

    // border colors (available | accepted | current | arrived | completed | aborted)
    &--available {
        border-color: $color-task-border-available;
    }

    &--accepted {
        border-color: $color-task-border-accepted;
    }

    &--current {
        border-color: $color-task-border-current;
    }

    &--arrived {
        border-color: $color-task-border-arrived;
    }

    &--completed {
        border-color: $color-task-border-completed;
    }

    &--aborted {
        border-color: $color-task-border-aborted;
    }

    &--address-warning {
        background-color: $color-hcp-bg-warning;
    }
}

.ucr__calendar-job-side {
    flex: 0 0 auto;
    padding: 3px;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
    color: rgba($color-white, 0.8);

    &--available {
        background-color: $color-task-border-available;
    }

    &--accepted {
        background-color: $color-task-border-accepted;
    }

    &--current {
        background-color: $color-task-border-current;
    }

    &--arrived {
        background-color: $color-task-border-arrived;
    }

    &--completed {
        background-color: $color-task-border-completed;
    }

    &--aborted {
        background-color: $color-task-border-aborted;
    }
}

.ucr__calendar-job-icons {
    flex-grow: 1;
    padding-top: 5px;
}

.ucr__calendar-job-fullName {
    max-width: 100%;
    @include font-h5;
    font-weight: bold;
    padding-bottom: 2px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 12px;

    &--admin-time {
        @extend .ucr__calendar-job-fullName;
        font-weight: normal;
    }
}

.ucr__calendar-job-about {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    padding: 6px 6px 0;
    position: relative;

    &--pl {
        padding-left: 24px;
    }
}

.ucr__calendar-job-postCode,
.ucr__calendar-job-icons {
    position: relative;
}

.ucr__calendar-job-postCode {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ucr__calendar-job-icon {
    display: inline-flex;

    &--center {
        align-self: center;
    }
}
.ucr__calendar-job-icon > svg {
    width: 24px;
    height: 24px;
}
.ucr__calendar-job-icon-unresolved > svg {
    fill: #ffffffff;
}
.ucr__calendar-job-icon-resolved > svg {
    fill: #ff0000ff;
}

.ucr__calendar-job-postCode,
.ucr__calendar-job-pathway,
.ucr__calendar-job-date {
    @include font-h5;
    padding-bottom: 2px;
    text-transform: uppercase;
    display: flex;
    text-align: initial;
}

.ucr__calendar-job-date--red {
    font-weight: 700;
    color: $color-red;
}

.ucr__calendar-icon {
    background-color: transparent;
    fill: $color-text-dark;
    pointer-events: none;
    cursor: default;
    margin: 0 2px 0 0;
    width: 18px;
    height: 18px;

    &--alert {
        fill: $color-red;
    }

    .ucr__calendar-job--alert-unresolved & {
        fill: $color-white;
    }
}

.ucr__calendar-time-text {
    padding-right: 2px;

    &--alert {
        color: $color-red;
    }
}

.ucr__calendar-job-status {
    @include font-h5;
    text-align: center;
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ucr__calendar-visit {
    display: block;
    border: none;
    padding: 0;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.ucr__calendar-visit-empty {
    @include font-p;
    height: 80px;
    color: rgba($color-grey, 50%);
    background-color: $color-light;
    border-radius: $border-radius-sm;

    align-items: center;
    justify-content: center;
    display: flex;
}

.ucr__calendar-visit-main {
    padding: 10px;
    border: 1px solid $color-text-main;
    border-radius: $border-radius-sm;

    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    display: flex;

    &--routine {
        background-color: $color-task-bg-follow-up;
    }
    &--urgent {
        background-color: $color-task-bg-urgent;
    }
}

.ucr__calendar-visit-fullName {
    @include font-h5;
    padding-bottom: 2px;
    display: block;
}

.ucr__calendar-visit-postCode {
    @include font-h6;
    text-transform: uppercase;
    display: block;
}
.ucr__calendar-assigned-job-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
}

.ucr__calendar-time-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2px;
    @include font-h5;

    &--alert {
        color: $color-red;
    }
}

.ucr__calendar-job-assigned-latest-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2px;
}

.ucr__calendar-job-multi-icons-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ucr__calendar-job-icons-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: auto 0 0;
    padding: 2px 5px;
    position: relative;
    width: 100%;

    .ucr__calendar-job--alert-unresolved & {
        background-color: transparent;
    }

    &:empty {
        display: none;
    }
}

.ucr__calendar-job--has-patient-alert {
    fill: white;
    color: white;
}

.ucr__calendar-hcp-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 10px 0 -50px;
    padding-top: 10px;
    width: 100%;

    .v2__form-group .v2__form-multi-select-input .bp5-input {
        min-height: 30px;
    }

    .bp5-form-content {
        margin-left: 7px;
        margin-right: 7px;
    }

    flex-grow: 1;

    &--map-pl {
        margin: 10px 10px 0 -70px;
    }
}

.ucr__calendar-hcp-sickness {
    display: inline-block;

    .ucr__calendar-hcp-sickness-select {
        color: $color-text-main;
        background-color: #fff;
        outline: none 0;
        font-size: 12px;
        font-weight: normal;
        position: relative;
        padding-right: 25px;
        box-shadow: none;
        border: 1px solid #d3dadd;
        height: 34px;

        .bp5-button-text {
            width: 46px;
            margin-right: 4px;
        }
    }
    .ucr__calendar-hcp-sickness-select-icon {
        fill: $color-text-main;
        margin-left: -8px;
        margin-right: 0;
    }
    &--sick {
        .ucr__calendar-hcp-sickness-select {
            color: $color-red;
        }
        .ucr__calendar-hcp-sickness-select-icon {
            fill: $color-red;
        }
    }
}

.ucr__calendar-hcp-sickness-option {
    @include font-p;
    align-items: center;
    color: $color-text-dark;
    font-weight: 500;

    &--active {
        background-color: transparent !important;
        color: $color-text-dark !important;
    }
}

.ucr__calendar-hcp-functionality-selected-icon {
    fill: $color-grey;
    position: relative;
    top: 3px;
}

.bp5-menu {
    min-width: 98px;
    padding-left: 0;
    padding-right: 0;
}

.ucr__calendar-hcp-functionality-select {
    .ucr__calendar-hcp-functionality-select-wrap {
        height: 34px;
        width: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        margin-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        padding-left: 30px;
        font-size: 12px;
        box-shadow: none;
        border: 1px solid #d3dadd;
        outline-width: 0px;
        .bp5-tag-input-values {
            flex-wrap: nowrap;
            width: 150px;
            overflow: hidden;
            .bp5-tag {
                background: transparent;
                text-overflow: ellipsis;
                font-size: 12px;
                color: #546e7a;
            }
        }

        .bp5-input-ghost::placeholder {
            color: #546e7a;
        }

        .ucr__calendar-hcp-functionality-select-icon {
            fill: $color-blue;
            position: absolute;
            left: 5px;
            top: 50%;
            margin-top: -10px;
        }
    }
}

.ucr__calendar-hcp-functionality-select-popover {
    min-width: 150px;

    .ucr__calendar-hcp-functionality-select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-text-dark;
        font-weight: 500;

        .bp5-icon {
            margin-right: 5px;
        }
        .ucr__calendar-hcp-functionality-icon-placeholder {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
        }
        .bp5-fill {
            font-size: 12px;
        }
    }
}

.ucr__calendar-hcp-availability-wrap {
    cursor: pointer;
    position: relative;

    .bp5-input-group .bp5-input {
        height: 34px;
        font-size: 12px;
        margin-left: 7;
        box-shadow: none;
        cursor: pointer;
        outline-width: 0px;
        border: 1px solid $color-border-main;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: transparent;
        text-shadow: 0 0 0 $color-text-main;
    }

    .form-control {
        border: 0;
    }
}

.ucr__calendar-hcp-availability-icon {
    fill: $color-blue;
    position: absolute;
    top: 7px;
    left: 5px;
    z-index: 9;
    outline: none;
    border: none;
}

.ucr__calendar-hcp-availability-expand-icon {
    fill: $color-text-main;
    position: absolute;
    width: 20px;
    top: 7px;
    right: 5px;
    z-index: 9;
    outline: none;
    border: none;
}
.ucr__calendar-hcp-sickness-select-icon-arrow {
    fill: $color-text-main;
    position: absolute;
    width: 20px;
    top: 7px;
    right: 5px;
}

.ucr__calendar-hcp-availability {
    min-width: 158px;
    margin-left: 10px;
    outline: none;
}

.ucr__calendar-hcp-form {
    @include font-p;
    color: $color-text-main;
    padding: 10px;

    .bp5-form-content {
        font-size: 13px;
    }

    .v2__form-group .v2__form-select-input {
        width: 140px;
    }

    .v2__form-group .v2__form-select-input select {
        height: 30px;
        line-height: 30px;
        padding-right: 20px;
    }
    .v2__form-group .v2__form-select-input .bp5-icon {
        top: 7px;
        right: 5px;
    }

    .bp5-icon > svg:not([fill]) {
        width: 13px;
    }

    .v2__form-group .v2__form-time-input input.bp5-timepicker-input {
        height: 30px;
        line-height: 30px;
    }

    .bp5-form-content .bp5-timepicker .bp5-timepicker-input-row {
        height: 30px;
        line-height: 30px;
    }

    .v2__form-group .v2__form-time-input .bp5-timepicker-divider-text {
        height: 30px;
        line-height: 30px;
    }

    .v2-callout--warning {
        max-width: 198px;
        font-size: 13px;
        padding: 5px 10px;

        .v2-callout__icon {
            display: none;
        }
    }
}

.bp5-popover2.bp5-popover2-placement-bottom {
    position: relative;
    top: -15px;
}

.bp5-popover2-transition-container {
    z-index: 20;
}

.ucr__calendar-hcp-formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;

    .bp5-form-content {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        .v2__form-time-input {
            margin-right: 0;
        }
    }

    &.button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
}

.ucr__calendar-s1-tag {
    position: absolute;
    right: 2px;
    top: 2px;
    font-size: 9px;
    padding: 1px 3px;
    background-color: $color-grey;
    color: $color-white;
    border-radius: 2px;
    z-index: 10;
    width: 18px;

    &--na {
        background-color: $color-task-border-accepted;
    }
    &--loading {
        background-color: $color-task-border-accepted;
    }
    &--synced {
        background-color: $color-task-border-completed;
    }
    &--not_synced {
        background-color: $color-task-border-aborted;
    }

    &--referral-set {
        top: 16px;
        right: 0px;
        fill: $color-white;
        height: 18px;
        width: 18px;
        background-color: $color-task-border-completed;
    }

    &--referral-not-set {
        top: 16px;
        right: 0px;
        fill: $color-white;
        height: 18px;
        width: 18px;
        background-color: $color-task-border-aborted;
    }
}
