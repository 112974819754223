.patient-data {
    padding: 20px;

    .patient-data-table {
        padding: 0;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 20px;

        .bp5-form-content {
            min-height: 30px;
            display: flex;
            flex-direction: column;
        }

        .patient-data-table__row:hover td {
            background-color: $lightGreyBackground;
        }
    }
}

.patient-data-table__header {
    background-color: #e5e9eb;

    .bp5-control {
        margin-top: 0;
    }
}

.patient-data-table__cell {
    color: $personTitleColor;
    font-size: 12px;

    &--alignTop {
        vertical-align: top !important;
        padding: 12px !important;

        .v2__form-group {
            .v2__form-radio-input {
                margin-top: 0;
            }

            &.bp5-control-indicator--small .bp5-form-content .v2__form-checkbox-input {
                margin-top: 0;
                margin-bottom: 10px;
            }

            &:last-of-type {
                .v2__form-radio-input, .v2__form-checkbox-input {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.patient-data-table__cell,
.patient-data-table__header {
    border-right: 1px solid $divider;

    &:last-of-type,
    &:last-of-type {
        border-right: none;
    }
}
